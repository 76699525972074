<template>
  <v-container grid-list-md pa-0 relative>
    <v-layout row wrap>
      <v-flex xs12>
        <v-card>
          <v-card-title class="title">
            <v-icon>layers</v-icon>
            Account Statement
          </v-card-title>
          <v-card-text>
            <v-form
              ref="form"
              @submit.prevent="store"
              @keydown.native="form.errors.clear($event.target.name)"
              @keyup.enter="store"
              lazy-validation
            >
              <v-layout wrap>
                <v-flex xs12 sm3>
                  <v-autocomplete
                    v-model="ledger_head_id"
                    :items="itemsLedgerHeads"
                    :search-input.sync="search"
                    hide-no-data
                    item-text="Name"
                    item-value="id"
                    label="Ledger Head"
                    placeholder="Start typing to Search"
                    :height="25"
                  ></v-autocomplete>
                </v-flex>
                <v-flex xs12 sm3>
                  <v-text-field
                    label="From Date"
                    v-model="form.frmDate"
                    name="frmDate"
                    prepend-icon="event"
                    mask="####-##-##"
                    placeholder="YYYY-MM-DD"
                    :error-messages="form.errors.get('frmDate')"
                    :height="25"
                    :readonly="form.loading"
                  />
                </v-flex>
                <v-flex xs12 sm3>
                  <v-text-field
                    label="To date"
                    v-model="form.toDate"
                    name="toDate"
                    prepend-icon="event"
                    mask="####-##-##"
                    placeholder="YYYY-MM-DD"
                    :error-messages="form.errors.get('toDate')"
                    :height="25"
                    :readonly="form.loading"
                  />
                </v-flex>
                <v-flex xs12 sm1></v-flex>
                <v-flex xs12 sm2>
                  <v-btn
                    color="primary"
                    dark
                    outline
                    @click="submit"
                    class="text-xs-right"
                    :disabled="!ledger_head_id"
                  >
                    <v-icon small>add</v-icon>
                    Search
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-form>
            <transition
              name="animate-css-transition"
              enter-active-class="animated fadeInRight"
              leave-active-class="animated fadeOutRight"
            >
              <v-btn
                v-if="download"
                fab
                small
                bottom
                right
                absolute
                @click.native.stop="printReport"
                style="z-index: 1; background: blue;bottom: 13px; right: 7px"
                dark
              >
                <v-icon dark>cloud_download</v-icon>
              </v-btn>
            </transition>
          </v-card-text>
        </v-card>

        <v-data-table
          :headers="headers"
          :items="form.items.data"
          :rows-per-page-items="rowsPerPageItems"
          :pagination.sync="pagination"
          class="elevation-1"
        >
          <template slot="items" slot-scope="props">
            <tr v-if="props.index == 0">
              <td colspan="6">Opening Balance</td>
              <td
                v-if="
                  form.items.data[0].balance +
                    form.items.data[0].cr -
                    form.items.data[0].dr !=
                    0
                "
              >
                {{
                  form.items.data[0].balance +
                    form.items.data[0].cr -
                    form.items.data[0].dr >
                  0
                    ? (
                        form.items.data[0].balance +
                        form.items.data[0].cr -
                        form.items.data[0].dr
                      ).numberFormat() + " Dr"
                    : Math.abs(
                        form.items.data[0].balance +
                          form.items.data[0].cr -
                          form.items.data[0].dr
                      ).numberFormat() + " Cr"
                }}
              </td>
              <td v-else>
                0.00
              </td>
            </tr>
            <tr>
              <td>{{ props.item.entry_date }}</td>
              <td>{{ props.item.voucher_type }}</td>
              <td>{{ props.item.voucher_no }}</td>
              <td>{{ props.item.opposite_ledger_head }}</td>
              <td>
                {{
                  props.item
                    ? props.item.dr
                      ? props.item.dr.numberFormat()
                      : 0
                    : 0
                }}
              </td>
              <td>
                {{
                  props.item
                    ? props.item.cr
                      ? props.item.cr.numberFormat()
                      : 0
                    : 0
                }}
              </td>
              <td>
                {{
                  props.item
                    ? props.item.balance
                      ? props.item.balance > 0
                        ? props.item.balance.numberFormat() + " Dr"
                        : Math.abs(props.item.balance).numberFormat() + " Cr"
                      : 0
                    : 0
                }}
              </td>
            </tr>
            <tr v-if="props.index == form.items.data.length - 1">
              <td colspan="6">Closing Balance</td>
              <td>
                {{
                  form.items.data[form.items.data.length - 1].balance > 0
                    ? Math.abs(
                        form.items.data[form.items.data.length - 1].balance
                      ).numberFormat() + " Dr"
                    : Math.abs(
                        form.items.data[form.items.data.length - 1].balance
                      ).numberFormat() + " Cr"
                }}
              </td>
            </tr>
          </template>
          <template v-slot:no-data v-if="openingDataDisplay">
            <td colspan="6">Opening Balance</td>
            <td>
              {{
                openingBalance > 0
                  ? openingBalance.numberFormat() + " Dr"
                  : Math.abs(openingBalance).numberFormat() + " Cr"
              }}
            </td>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import Form from "@/library/Form";

const NepaliDate = require("nepali-date-js");
const nd = new NepaliDate();
import { passDataToExternalDomain } from "@/library/helpers";

export default {
  name: "JournalList",
  data: () => ({
    form: new Form(
      {
        ledger_head_id: "",
        frmDate: nd.format("YYYY-MM-DD"),
        toDate: nd.format("YYYY-MM-DD"),
      },
      "/api/statement-account"
    ),
    openingBalance: 0,
    ledger_head_id: "",
    download: false,
    ledgerHeads: [],
    rowsPerPageItems: [5, 10, 25, 50, -1],
    pagination: {
      rowsPerPage: 10,
    },
    openingDataDisplay: false,
    search: null,
    entries: [],
    headers: [
      {
        text: "Entry Date",
        value: "entry_date",
        align: "left",
        width: 130,
        sortable: false,
      },
      {
        text: "V. Type",
        value: "voucher_type",
        align: "left",
        sortable: false,
      },
      {
        text: "V. No",
        value: "voucher_no",
        align: "left",
        width: 100,
        sortable: false,
      },
      {
        text: "Ledger Head",
        value: "ledger_head",
        align: "left",
        sortable: false,
      },
      { text: "Dr.", value: "dr", align: "left", sortable: false, width: 140 },
      { text: "Cr.", value: "cr", align: "left", sortable: false, width: 140 },
      {
        text: "Balance.",
        value: "balance",
        align: "left",
        sortable: false,
        width: 140,
      },
    ],
  }),
  mounted() {
    this.entries = [];
    if (![undefined, null, "", NaN].includes(this.$route.query.id)) {
      this.entries.push({
        id: this.$route.query.id,
        text: this.$route.query.name,
        Name: this.$route.query.name,
        name: this.$route.query.name,
      });

      this.ledger_head_id = this.$route.query.id;
      this.form.frmDate = this.$route.query.frmDate;
      this.form.toDate = this.$route.query.toDate;
      this.$rest
        .get(
          "/api/statement-account?ledger_head_id=" +
            this.ledger_head_id +
            "&frmDate=" +
            this.form.frmDate +
            "&toDate=" +
            this.form.toDate
        )
        .then(({ data }) => {
          this.form.items.data = data.map((item) => {
            return item;
          });
          this.openingBalance = data.opening;
          if (this.form.items.data.length > 0) this.download = true;
          else this.download = false;
        });
    }
  },

  methods: {
    submit() {
      this.form.frmDate = this.dateFormat(this.form.frmDate);
      this.form.toDate = this.dateFormat(this.form.toDate);
      if (this.ledger_head_id) {
        this.$rest
          .get(
            "/api/statement-account?ledger_head_id=" +
              this.ledger_head_id +
              "&frmDate=" +
              this.form.frmDate +
              "&toDate=" +
              this.form.toDate +
              "&rowsPerPage=" +
              this.pagination.rowsPerPage
          )
          .then(({ data }) => {
            this.openingDataDisplay = true;
            let totalAmount = data.opening;
            this.form.items.data = data.data.map((item) => {
              totalAmount = totalAmount - item.cr;
              totalAmount = totalAmount + item.dr;
              item.balance = totalAmount;
              return item;
            });
            this.openingBalance = data.opening;
            if (this.form.items.data.length > 0) this.download = true;
            else this.download = false;
          });
      }
    },

    printReport() {
      this.form.frmDate = this.dateFormat(this.form.frmDate);
      this.form.toDate = this.dateFormat(this.form.toDate);
      this.$rest.get("/api/download/statement").then((res) => {
        let url =
          res.data.data.download_url +
          "?ledgerHeadId=" +
          this.ledger_head_id +
          "&fromDate=" +
          this.dateFormat(this.form.frmDate) +
          "&toDate=" +
          this.dateFormat(this.form.toDate);
        passDataToExternalDomain();
        setTimeout(() => {
          window.open(url);
        }, 300);
      });
    },

    dateFormat(data) {
      if (data.length === 8)
        return (data =
          data.substr(0, 4) + "-" + data[4] + data[5] + "-" + data.slice(-2));
      else return data;
    },

    setEntryDate() {
      if (this.form.frmDate == null) {
        // let date = this.$converter.todayNP();
        // this.form.frmDate = `${date.year}-${date.month.pad(2)}-${date.day.pad(2)}`;
      }
      if (this.form.toDate == null) {
        // let date = this.$converter.todayNP();
        // this.form.toDate = `${date.year}-${date.month.pad(2)}-${date.day.pad(2)}`;
      }
    },
  },

  computed: {
    itemsLedgerHeads() {
      return this.entries.map((entry) => {
        return Object.assign({}, entry, { Name: entry.name });
      });
    },
  },
  created() {
    if (this.form.ledger_head_id) this.form.get();
    this.setEntryDate();
  },
  watch: {
    search(val) {
      if (val != this.$route.query.name) {
        if (!val) return;
        // if (this.isLoading) return;

        this.isLoading = true;
        this.$rest
          .get("/api/ledger-head?search=" + val)
          .then(({ data }) => {
            this.entries = data.data.map((res) => {
              return { name: res.name, text: res.name, id: res.id };
            });
          })
          .catch((err) => {})
          .finally(() => (this.isLoading = false));
      }
    },
    ledger_head_id: function(v) {
      this.openingDataDisplay = false;
      this.submit();
    },
    pagination: function() {
      this.submit();
    },
  },
};
</script>
<style lang="scss" scoped>
.opening {
  background: #4caf50;
  margin-left: 5px;
  color: white;
  padding: 5px 5px;
  margin-top: 5px;
}
</style>
